<template>
  <Layout>
    <template
      v-if="row.label"
      v-slot:label
    >
      {{ row.label }}
    </template>

    <template
      v-if="row.extra"
      v-slot:extra
    >
      {{ row.extra }}
    </template>

    <template v-slot:input>
      <div class="flex">
        <!-- <toggle-button
          class="mr-2"
          v-bind:value="isEnabled"
          v-bind:sync="true"
          v-bind:disabled="isDisabled"
          v-bind:width="inputWidth"
          v-bind:height="inputHeight"/> -->

        <label
          :for="inputId"
          class="text-center text-xs leading-tight text-blue-500 hover:underline hover:text-blue-400 cursor-pointer"
        >
          Update <fa-icon
            icon="file-image"
            class="fa-fw"
          />
        </label>
      </div>

      <input
        :id="inputId"
        ref="fileSelector"
        class="hidden"
        name="asset"
        type="file"
        value="Upload Asset"
        accept=".png,.jpg,.jpeg,.gif"
        @change="handleChange"
      >
    </template>
  </Layout>
</template>
<script>
import { generateUuid } from '@utils/misc.js'
import inputs from './InputMixin'

export default {
  name: 'TextureInput',

  mixins: [inputs],

  data () {
    return {
      inputId: 'file-input'
    }
  },

  computed: {

    hasValue () {
      return true
    },

    inputHeight () {
      return 17
    },

    inputWidth () {
      return 35
    },

    isEnabled () {
      return (this.model.enabled === true)
    }
  },

  mounted () {
    this.generateId()
  },

  methods: {
    generateId () {
      this.inputId = 'file-input-' + generateUuid()
    }
  }

}

</script>
